<template>
  <view-item title="定金列表">
    <template #operation>
      <en-button type="primary" @click="operation.add.click">新建定金收款</en-button>
    </template>
    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <form-query :model="form.data" :method="table.get">
            <en-form-item label="付款搜索">
              <en-input v-model="form.data.quickSearch" placeholder="付款对象/单号"></en-input>
            </en-form-item>
          </form-query>
          <tabs-maintain
            v-model="tabs.statusCode"
            :ajax="{
              action: 'GET /enocloud/common/lookup/:lookupType',
              params: (params) => (params.paths = ['RVBSTAT']),
              convert: (data) => data.filter((item) => item.code !== 'C')
            }"
            :props="{ value: 'code', label: 'message' }"
            :method="table.get"
          ></tabs-maintain>

          <flex-box>
            <template #default="{ height }">
              <table-dynamic
                :data="table.data"
                :method="table.get"
                :paging="table.paging"
                :height="height - 55"
                pagination
                :loading="table.loading"
                code="DPSTRAB"
                :config="table.config"
              >
                <template #SERIAL_NO="{ row }: { row: EnocloudSettlementDefinitions['ReceivableDto'] }">
                  <en-button type="primary" link @click="table.operation.name.click(row)">{{ row.serialNo }}</en-button>
                </template>
              </table-dynamic>
            </template>
          </flex-box>
        </template>
      </flex-box>
    </en-card>
  </view-item>
  <en-drawer v-model="detail.visible" title="新建定金收款" @close="$emit('update:model-value', false)">
    <en-form :model="detail.form.data" :rules="detail.form.rules" :loading="detail.form.loading" :ref="setRef('detailForm')">
      <en-form-item label="单据日期" prop="preparedDatetime">
        <en-date-picker v-model="detail.form.data.preparedDatetime" class="w-full" disabled></en-date-picker>
      </en-form-item>
      <en-form-item label="单据单号" prop="serialNo">
        <en-input v-model="detail.form.data.serialNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="制单人" prop="preparedBy">
        <en-input v-model="detail.form.data.preparedBy.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="门店">
        <select-maintain
          v-model="detail.form.data.branch"
          :ajax="{
            action: 'GET /enocloud/common/branch',
            params: (params) => {
              params.payload = { pagingEnabled: false }
            }
          }"
          :props="{ label: 'shortName', value: 'id' }"
          clearable
          class="w-full"
          :disabled="detail.form.formDataStatus === 'finish'"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="收款对象" prop="payer">
        <select-maintain
          v-model="detail.form.data.payer"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (params, value) => (params.payload = { name: value, rsexp: 'id,name,cellphone', })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="detail.form.formDataStatus === 'finish'"
          class="w-full"
          @change="form.payer.change"
        ></select-maintain>
      </en-form-item>

      <en-form-item label="车牌号">
        <select-maintain
          v-model="detail.form.data.depositPlateNo"
          :ajax="{
            action: 'GET /enocloud/common/vehicle',
            params: (params, value) => (params.payload = { plateNo: value, customerId: detail.form.data.payer.id })
          }"
          :props="{ label: 'plateNo', value: '' }"
          value-key="id"
          :key="detail.form.data.payer.id"
          class="w-full"
          :disabled="!detail.form.data.payer.id"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="联系电话">
        <en-input :value="detail.form.data.payer?.cellphone" disabled></en-input>
      </en-form-item>
      <en-form-item label="定金金额">
        <en-input-number v-model="detail.form.data.amount" :min="0" class="w-full" :disabled="detail.form.formDataStatus === 'finish'"></en-input-number>
      </en-form-item>
      <en-form-item label="备注" prop="comment">
        <en-input v-model="detail.form.data.comment"></en-input>
      </en-form-item>
    </en-form>
    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click">确定</button-ajax>
    </template></en-drawer
  >
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      tabs: {
        statusCode: ''
      },
      form: {
        data: {
          quickSearch: '',
          amount: '',
          branch: {},
          payer: {
            id: '',
            name: ''
          },
          preparedBy: {
            id: '',
            name: '',
            department: {
              name: ''
            }
          },
          preparedDatetime: '',
          serialNo: '',
          comment: '',
          status: {
            code: ''
          },
          depositPlateNo: '',
          type: {
            code: 'DPS',
            type: '',
            message: ''
          }
        },
        payer: {
          change() {
            this.detail.form.data.depositPlateNo = ''
          }
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/settlement/receivable/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { ...this.form.data, ...this.tabs }
            }
          }
        },
        children: {
          operation: {
            name: {
              click(row: EnocloudSettlementDefinitions['ReceivableDto']) {
                this.detail.form.init()
                this.detail.form.data.id = row.id
                this.detail.form.get()
                this.detail.visible = true
              }
            }
          }
        },
        config: {
          PREPARED_BY_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'preparedByName'
              }
            }
          },
          PLATE_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'depositPlateNo'
              }
            }
          },
          CELLPHONE: {
            header: {
              filter: {
                type: 'text',
                field: 'cellphone'
              }
            }
          }
        }
      },
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              amount: '',
              branch: {},
              payer: {
                id: '',
                name: ''
              },
              preparedBy: {
                id: '',
                name: '',
                department: {
                  name: ''
                }
              },
              preparedDatetime: '',
              serialNo: '',
              comment: '',
              status: {
                code: ''
              },
              depositPlateNo: '',
              type: {
                code: 'DPS',
                type: '',
                message: ''
              }
            },
            ajax: {
              get: {
                action: 'GET /enocloud/settlement/receivable/:receivableId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/settlement/receivable/deposit',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/settlement/receivable/deposit',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: {
              branch: [{ required: true, message: '请选择门店', trigger: 'blur' }],
              payer: [{ required: true, message: '请选择往来单位', trigger: 'change' }]
            },
            computed: {
              formDataStatus() {
                switch (this.detail.form.data.status.code) {
                  case 'P':
                    return 'pending'
                  case 'F':
                    return 'finish'
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
